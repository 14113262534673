import { API_URL } from './xhr.constants';
import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
  
      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
  
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          try {
            const response = await axios.post('/token/refresh/', { refresh: refreshToken });

            const { access, refresh } = response.data;

            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);
    
            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${access}`;
            return axios(originalRequest);
          } catch (error) {
            // Handle refresh token error or redirect to login
                window.location.href = '/login';

                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
            
          }
        } 
      return Promise.reject(error);
      }
    }
  );

export default axiosInstance;