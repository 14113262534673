import React, { useState, useEffect, useCallback } from 'react';
import { 
    Box,
    Typography,
    IconButton,
	Button,
	useTheme,
	useMediaQuery,
	TextField,
	Autocomplete
 } from '@mui/material'
import TimeCalender from './TimeCalender';
import moment from 'moment';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import TodayIcon from '@mui/icons-material/Today';
import { useDispatch, useSelector } from 'react-redux';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import TimeModalForm from './TimeModalForm';
import useStore from '../../zustand/store'
import axiosInstance from "../../xhr/axiosInstance"
import { getUser } from '../auth/services/user.service';

interface Props {
    dateChange: (date: any) => void
}

export default function TimeCalenderHeader() {
	const dateStore = useStore((state) => state.date)
	const setDateStore = useStore((state) => state.setDate)
	const setProjectStore = useStore((state) => state.setProject)
	const setUserStore = useStore((state) => state.setUser)
	const selectedDate = moment(dateStore)
	const dispatch = useDispatch()
	const [weekDates, setWeekDates] = useState<moment.Moment[]>([]);
	const [totalHours, setTotalHours] = useState<number>(0);
	const [timeEntryId, setTimeEntryId] = useState<string | undefined>()
	const [open, setOpen] = useState(false);
	const [users, setUsers] = useState<any[]>([]);
	const [user, setUser] = useState(getUser());
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const fetchUsers = async () => {
		const { data } = await axiosInstance.get('/users')
		setUsers([...data.results, {id: undefined, first_name: 'Alle', last_name: 'Medewerkers'}]);
	};

	useEffect(() => {
		const startOfWeek = dateStore.clone().startOf('week');
   		const weekDates = [];
		for (let i = 0; i < 7; i++) {
			const date = startOfWeek.clone().add(i, 'days');
			weekDates.push(date);
		}
		setWeekDates(weekDates);
  }, [dateStore]);
  
	const handleDateChange = (newDate: moment.Moment) => {
		setDateStore(newDate)
  };

  const updateTotalHours = (hours: number) => {
		setTotalHours(hours);
  };

	const getPreviousWeek = (selectedDate: moment.Moment) => {
		const previousWeek = moment(selectedDate).clone();
		previousWeek.subtract(7, 'd');
		setDateStore(previousWeek)
	};
	
	const getNextWeek = (selectedDate : moment.Moment) => {
		const nextWeek = moment(selectedDate);
		nextWeek.add(7, 'd'); 
		setDateStore(nextWeek)
	};

	const getCurrentWeek = () => {
		const currentweek = moment();
		setDateStore(currentweek)
	};

	const handleCreateNewClick = () => {
		setDateStore(moment())
		setProjectStore({project: undefined, project_client_name: undefined})
		setUserStore({id: undefined, first_name: undefined})
		setTimeEntryId(undefined);
		setOpen(true);
	}

	const handleCreateNewClickDate = (date: moment.Moment) => {
		setDateStore(date)
		setProjectStore({project: undefined, project_client_name: undefined})
		setUserStore({id: undefined, first_name: undefined})
		setTimeEntryId(undefined);
		setOpen(true);
	}

	useEffect(() => {
		fetchUsers();
	}, []);

	const onDataDelete = useCallback(() => {
		getCurrentWeek(); 
	}, [])

	const handleChange = (event: React.SyntheticEvent, newValue: any) => {
		setUser(newValue)
	}

  return (
      <Box sx={{ flexDirection: 'column'}}>
        <Box sx={{ display: 'flex', height: '75px', flexDirection: 'row'}}>
		<Box sx={{ display: 'flex',  flexDirection: 'row', width: '100%'}}>
			<Box sx={{ flexGrow: 1, flex: 1}}>
			<Typography sx={{ textAlign: 'left', pl: 2, pt: 1 }}>
				{selectedDate.format("MMMM")}, {selectedDate.year()} 
            </Typography>
            <Typography variant="h6" sx={{ textAlign: 'left', pl: 2}}>
							{selectedDate.week()} 
            </Typography>
			</Box>
				{isMobile ? 
					<Box>
					<Typography  sx={{ textAlign: 'right', pr: 2, pt: 1 }}>
						Totaal uren
					</Typography>
					<Typography variant="h5" sx={{ textAlign: 'right', pr: 2 }}>
						{totalHours}
					</Typography>
					</Box>
					: <Box sx={{ display: 'flex', flex: 1, flexGrow: 1, justifyContent: 'end'}}>
					{getUser().is_superuser ? 
							<Box sx={{ width : '50%', alignSelf: 'center', mr: 2}}>
								<Autocomplete
									disableClearable
									options={users}
									onChange={handleChange}         
									getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
									value={user}
									isOptionEqualToValue={(option, value) => option.id === value?.id}
									renderInput={(params) => (
										<TextField
										{...params}
										label="Medewerker"
										InputProps={{
											...params.InputProps,
										}}
										sx={{bgcolor: 'white', borderRadius: 2}}
										/>
									)}                
								/>
							</Box>
						: <></>}
						<Box  sx={{ width:'auto', alignSelf: 'center'}}>
							<Button onClick={handleCreateNewClick} variant="contained" endIcon={<AddRoundedIcon />}>Uren regel</Button>	
						</Box>
						<TimeModalForm 
							open={open} 
							timeEntryId={timeEntryId}
							setOpen={setOpen}
							setTimeEntryId={setTimeEntryId}
							onDataDelete={onDataDelete}
							/>
					</Box>
				}
			</Box>
        </Box>
        <Box sx={{height: '38px', borderTop: 1, borderBottom: 1 , borderColor: 'primary.light', display: 'flex'}}>
					<Button onClick={() => getPreviousWeek(selectedDate)} sx={{flex: 1, display: 'block', textAlign: 'left'}} > 
						<KeyboardArrowLeftRoundedIcon sx={{ fontSize: '28px', color: '#424242'}}/>
					</Button>
					<Button onClick={() => getCurrentWeek()} sx={{flex: 1}}> 
						<TodayIcon sx={{ fontSize: '28px', color: '#424242'}}/>
					</Button>
					<Button onClick={() => getNextWeek(selectedDate)} sx={{flex: 1, display: 'block', textAlign: 'right'}}> 
						<KeyboardArrowRightRoundedIcon sx={{ fontSize: '28px', color: '#424242'}}/>
					</Button>
					</Box>
					<Box sx={{display: 'flex' }}>
					{weekDates.map((date, index) => (
						<Box key={date.format('DD')} sx={{ flexGrow:1, flex: 1}}>
						<Typography>
							{date.format('dd')}
							{isMobile ? <></> : 
								<IconButton sx={{m: 0, p: 0, selfAlign: 'end'}} onClick={() => handleCreateNewClickDate(date)} color="success"><AddRoundedIcon /></IconButton>	
							}
						</Typography>
							{date.format('DD') === selectedDate.format('DD') ?
								<Box sx={{textAlign: '-webkit-center'}}>
									<Typography  sx={{color: 'white', backgroundColor: 'primary.main', borderRadius: '50%', height: 28, width: 28}}>
										{date.format('DD')}
									</Typography>
									</Box>
								:
								moment().format('DD') === date.format('DD') ? 
								<Box sx={{textAlign: '-webkit-center'}} onClick={() => handleDateChange(date)}>
									<Typography sx={{border: 1, borderRadius: '50%', height: 28, width: 28, borderColor: 'primary.main',}}>
										{date.format('DD')}
									</Typography>
									</Box>
								:
								<Typography onClick={() => handleDateChange(date)}>
									{date.format('DD')}
								</Typography>
							}
							{isMobile ? <></>
			  					:
									<TimeCalender
										date={date}
										updateTotalHours={updateTotalHours}
										user={user}
									/>}
							</Box>					
					))}

			</Box>
			{isMobile ? 					
			<TimeCalender date={dateStore} updateTotalHours={updateTotalHours} user={user}/>
			: <></> }
		</Box>
  )
}
