import {
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from "@tanstack/react-query"
import {
    createTimeOffEntry,
    deleteTimeOffEntry,
    fetchTimeOffEntries,
    fetchTimeOffEntry,
    updateTimeOffEntry,
} from "./timeoffentries.queries"
import { IRestResponse, IRestParams } from "../../types/generic"
import { AxiosError } from "axios"
import  { TimeOffEntry, User }  from '../../types/all';


// Read one

export function useFetchTimeOffEntry(
timeOffEntryId: string,
options?: UseQueryOptions<TimeOffEntry>
) {
return useQuery<TimeOffEntry>(
    ["time-off-entries", timeOffEntryId],
    () => fetchTimeOffEntry(timeOffEntryId),
    options
)
}

// //list currentUser

// export function useFetchTimeOffEntriesForCurrentUser(
//     params: IRestParams,
//     options: UseQueryOptions<IRestResponse<TimeOffEntry>>,
//     currentUser: User | undefined // Assuming UserData is the type of currentUser.data
//   ) {
//     return useQuery<IRestResponse<TimeOffEntry>>(
//       ["time-off-entries", params],
//       () => {
//         // Check if currentUser is available and has an ID
//         if (currentUser && currentUser.id) {
//           // Modify the fetchTimeOffEntries function to include the user ID in the query
//           return fetchTimeOffEntries({ ...params, userId: currentUser.id });
//         } else {
//           // Handle the case when currentUser is not available
//           return Promise.reject("Current user data is not available.");
//         }
//       },
//       options
//     );
//   }
  

// List

export function useFetchTimeOffEntries(
params: IRestParams,
options: UseQueryOptions<IRestResponse<TimeOffEntry>>
) {
return useQuery<IRestResponse<TimeOffEntry>>(
    ["time-off-entries", params],
    () => fetchTimeOffEntries(params),
    options
)
}

// Create

export function useCreateTimeOffEntry(
options?: UseMutationOptions<TimeOffEntry, AxiosError, TimeOffEntry>
) {
return useMutation<TimeOffEntry, AxiosError, TimeOffEntry>(
    ["createTimeOffEntry"],
    createTimeOffEntry,
    options
)
}

// Update

export function useUpdateTimeOffEntry(
options?: UseMutationOptions<TimeOffEntry, AxiosError, TimeOffEntry>
) {
return useMutation<TimeOffEntry, AxiosError, TimeOffEntry>(
    ["updateTimeOffEntry"],
    updateTimeOffEntry,
    options
)
}

// Delete

export function useDeleteTimeOffEntry(
options?: UseMutationOptions<void, AxiosError, string>
) {
return useMutation(["deleteTimeOffEntry"], deleteTimeOffEntry, options)
}