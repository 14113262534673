import React from "react"
import { FieldValues, useForm } from "react-hook-form"
import axiosInstance from "../../xhr/axiosInstance"
import {
  InputField,
  PasswordField,
} from "@meierij-it/react-components-v2"
import Button from "@mui/material/Button"
import { Typography, Box } from "@mui/material"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useNavigate } from "react-router-dom";
import { setUser } from "./services/user.service"
import { defaultErrorMessage } from "../../helpers/messages"

const schema = yup
  .object()
  .shape({
    username: yup
      .string()
      .required("Dit veld is verplicht"),
    password: yup.string().required("Dit veld is verplicht"),
  })
  .required()

export default function LoginForm() {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ shouldUnregister: true, resolver: yupResolver(schema) })

  async function DoLogin(values: FieldValues) {
    try {
      const response = await axiosInstance.post("/token/", {
        username: values.username,
        password: values.password,
      })

      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);

      const userResponse = await axiosInstance.get("/users/get_current_user/")
      
      setUser(userResponse.data)
      // Redirect to the dashboard
      navigate("/dashboard");
    } catch (error) {
      defaultErrorMessage("Ongeldige inloggegevens. Probeer opnieuw");
    }
  }

  return (
    <form onSubmit={handleSubmit(DoLogin)}>
      <Box sx={{ flexDirection: 'column', mx: 'auto', "& > *": { mt: 2 },
        boxShadow: 2,
        borderRadius: 2,
        p: 5 
        }}>
        <Typography variant="h5">
          Inloggen
        </Typography>
        <InputField
          label="Gebruikersnaam"
          control={control}
          error={errors.username}
          {...register("username")}
        />
        <PasswordField
          label="Password"
          control={control}
          error={errors.password}
          {...register("password")}
        />
        <Button variant="contained" color="primary" fullWidth type="submit">
          Sign in
        </Button>
      </Box>
    </form>
  )
}
