// import colorMode from "@/features/filters/colorModeSlice"
import createFiltersWithNamedType from "../features/filters/filtersSlice"
import notifications from "../features/filters/notificationsSlice"
import { configureStore } from "@reduxjs/toolkit"

const store = configureStore({
  reducer: {
    // colorMode,
    dashboard: createFiltersWithNamedType("DASHBOARD"),
    timeentries: createFiltersWithNamedType("TIMEENTRIES"),
    timeoffentries: createFiltersWithNamedType("TIMEOFFENTRIES"),
    projects: createFiltersWithNamedType("PROJECTS"),
    users: createFiltersWithNamedType("USERS"),
    // permissions,
    notifications: notifications,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
