import React from 'react'
import HoursBarChart from './HoursBarChart';
import Box from '@mui/material/Box';
export default function Dashboard() {
  return (
    <Box>
    <h2>Dashboard</h2>
    <HoursBarChart />
    </Box>
  )
}
