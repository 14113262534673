import {
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
} from "@tanstack/react-query"
import {
    createTimeEntry,
    deleteTimeEntry,
    fetchTimeEntries,
    fetchTimeEntry,
    updateTimeEntry,
} from "./timetracking.queries"
import { IRestResponse, IRestParams } from "@/types/generic"
import { AxiosError } from "axios"
import  { TimeEntry }  from '@/types/all';


// Read one

export function useFetchTimeEntry(
timeEntryId: string,
options?: UseQueryOptions<TimeEntry>
) {
return useQuery<TimeEntry>(
    ["time-entries", timeEntryId],
    () => fetchTimeEntry(timeEntryId),
    options
)
}

// List

export function useFetchTimeEntries(
params: IRestParams,
options: UseQueryOptions<IRestResponse<TimeEntry>>
) {
return useQuery<IRestResponse<TimeEntry>>(
    ["time-entries", params],
    () => fetchTimeEntries(params),
    options
)
}

// Create

export function useCreateTimeEntry(
options?: UseMutationOptions<TimeEntry, AxiosError, TimeEntry>
) {
return useMutation<TimeEntry, AxiosError, TimeEntry>(
    ["createTimeEntry"],
    createTimeEntry,
    options
)
}

// Update

export function useUpdateTimeEntry(
options?: UseMutationOptions<TimeEntry, AxiosError, TimeEntry>
) {
    return useMutation<TimeEntry, AxiosError, TimeEntry>(
        ["updateTimeEntry"],
        updateTimeEntry,
        options
    )
}

// Delete

export function useDeleteTimeEntry(
options?: UseMutationOptions<void, AxiosError, string>
) {
return useMutation(["deleteTimeEntry"], deleteTimeEntry, options)
}