import React ,{ useEffect, useState } from 'react'
import axiosInstance from "../../xhr/axiosInstance";
import { getUser } from '../auth/services/user.service'
import { IconButton, Box } from '@mui/material';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

export default function TimeOffTotal() {
    const [totalDaysUsed, setTotalDaysUsed] = useState(0);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


 
    const fetchData = async () => {
        try {
          const { data } = await axiosInstance.get(`/time-off-entries/?user=${getUser().id}`);
      
          const entriesThisYear = data.results.filter((entry: any) => {
            const entryYear = new Date(entry.date).getFullYear();
            return entryYear === selectedYear && entry.time_off_type === 'VACATION' && entry.approved_by != null;
          });

          const totalHoursUsed = entriesThisYear.reduce((total : number, entry: any) => {
            const entryHours = parseFloat(entry.hours) || 0;
            return total + entryHours;
          }, 0);

          const totalDaysUsed = totalHoursUsed / 8;
      
          setTotalDaysUsed(totalDaysUsed);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      

    useEffect(() => {
        fetchData();
    }, [selectedYear]);

    const handleYearChange = (yearChange: number) => {
        const newYear = selectedYear + yearChange;
        setSelectedYear(newYear);
      };
    
    return (
        <Box sx={{textAlign: 'left'}}>
                <IconButton onClick={() => handleYearChange(-1)}>
                    <KeyboardArrowLeftRoundedIcon />
                </IconButton>
            <span> {totalDaysUsed} / 25 vrije dagen gebruikt in {selectedYear.toString()} </span>
                <IconButton onClick={() => handleYearChange(1)}>
                <KeyboardArrowRightRoundedIcon />
                </IconButton>
        </Box>
  )
}
