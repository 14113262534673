import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Box,
	useTheme,
	useMediaQuery
} from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useDeleteTimeOffEntry } from "./timeoffentries.hooks"
import axiosInstance from "../../xhr/axiosInstance"

export interface IProps {
  	setDeleteOpen: (open: boolean) => void
	openDelete: boolean
	timeOffEntryId: any
	onDataDelete: () => void
	setTimeOffEntryId: (id: string | undefined) => void
}

export default function TimeDelete(props: IProps) {
	const { setDeleteOpen, openDelete, timeOffEntryId, onDataDelete, setTimeOffEntryId } = props

	const queryClient = useQueryClient()
	const theme = useTheme();
  	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [timeOffEntry, setTimeOffEntry] = React.useState<any>(undefined)

	useEffect(() => {

		if (timeOffEntryId && timeOffEntryId !== "new") {
		  axiosInstance.get(`/time-off-entries/${timeOffEntryId}/`).then((response : any) => {
			setTimeOffEntry(response.data)
		  })
		}
	  }, [timeOffEntryId])


	const onClose = () => {
		setDeleteOpen(false)
		setTimeOffEntryId(undefined)
	}

  /**
   * Runs after successful deletion
   */
  function onDeleteSuccess() {
    queryClient.invalidateQueries(["time-off-entries"])
	onClose()
  }

	const deleteMutation = useDeleteTimeOffEntry({
		onSuccess: onDeleteSuccess,
	})
		
	/**
	 * Delete timeEntry on server
	 */
	const onDelete = useCallback(() => {
	if (timeOffEntry) {
		deleteMutation.mutate(timeOffEntry.id)
	}
	}, [timeOffEntry])


	const doDelete = useCallback(() => {
		onDelete()
	}, [onDelete])

	return (
			<Dialog open={openDelete} onClose={onClose}>
			<DialogTitle>
					<Grid container justifyContent="space-between">
							<Grid item>
								<Typography variant="h6">
								Dit word verwijderd: ID: {timeOffEntry?.id}
								</Typography>
							</Grid>
							<Grid item>
							</Grid>
					</Grid>
					</DialogTitle>
					<DialogContent dividers>
					{timeOffEntry ? 
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box><span style={{ fontWeight: 'bold' }}>Gebruiker:</span> {timeOffEntry.first_name}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Datum:</span> {timeOffEntry.date}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Hoeveelheid uren:</span> {timeOffEntry.hours}</Box>

						</Box>
						: <></>
					}
					</DialogContent>
					<DialogActions>
						<Button variant="outlined"  onTouchStart={onClose} onClick={onClose} sx={{bgcolor: 'primary'}}>
							Cancel
						</Button> 
						<Button variant="outlined" color="error" onTouchStart={doDelete} onClick={doDelete} sx={{bgcolor: 'primary'}}>
							Delete
						</Button>
					</DialogActions>
			</Dialog>
	)
}
