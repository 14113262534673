import React, {useState, useEffect, useCallback } from 'react';
import { Box, 
	Button, 
	FormControl, 
	TextField, 
} from '@mui/material';
import { Project, User } from "../../types/all"
import axiosInstance from "../../xhr/axiosInstance"
import { sendSlackNotification } from "../../xhr/slackNotification"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import {
  useCreateTimeOffEntry,
  useUpdateTimeOffEntry,
} from "./timeoffentries.hooks"
import { useQueryClient } from "@tanstack/react-query"
import {
	dehydrateForeignKey,
	hydrateForeignKey,
	hydrateDateTime,
	hydrateSelect,
	hydrateTime,
	dehydrateTime
} from "../../helpers/normalizers"
import { 
	DateField, 
	CheckboxField, 
	TextareaField,
	AsyncSelectField,
	StaticSelectField, 
} from "@meierij-it/react-components-v2"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from "react-hook-form";
import { modalStyle, modalStyleMobile } from "../../styling"
import { getUser } from '../auth/services/user.service';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TimeField} from '../../components/TimeField';
import moment from 'moment';

export interface IProps {
	setOpen : Function
	timeOffEntryId: string | undefined
	setTimeOffEntryId: Function
}

const schema = yup
  .object({
		date: yup.string().required("Dit veld is verplicht"),
		time_off_type: yup.object().nullable().required("Dit veld is verplicht"),
		user: yup.object().nullable().required("Dit veld is verplicht"),
		approved_by: yup.string().nullable(),
		hours: yup.string().nullable().required("Dit veld is verplicht")
	  	.test({
			name: 'min',
			message: 'Minimale waarde is 0',
			test: (value) => {
				if (!value) {
					return false;
				}
			const numericValue = parseFloat(value);
			return isNaN(numericValue) || numericValue >= 0;
			},
		}),
})
.required();

export default function TimeOffForm(props: IProps) {
  const { timeOffEntryId, setTimeOffEntryId, setOpen } = props; 
	const navigate = useNavigate();

	const defaultValues = {
		date: new Date(), 
		user: dehydrateForeignKey(getUser().id, getUser().first_name), 
		time_off_type: { value: 'VACATION', label: 'Vakantie' },
	}

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const queryClient = useQueryClient()

	const {
	control,
	handleSubmit,
	register,
	reset,
	setValue,
	formState: { errors },
  } = useForm<any>({
	defaultValues,
	shouldUnregister: true,
	resolver: yupResolver(schema),
  })

  const [startTime, setStartTime] = useState<string | null>(null);;
  const [endTime, setEndTime] = useState<string | null>(null);;
  const [hours, setHours] = useState('');
  const { id } = useParams();
	const TIME_OFF_TYPES = [
		{ value: 'VACATION', label: 'Vakantie' },
		{ value: 'SICK', label: 'Ziek' },
		{ value: 'UNPAID', label: 'Onbetaald' },
		{ value: 'OTHER', label: 'Anders' },
	];

  useEffect(() => {
    if (timeOffEntryId && timeOffEntryId !== "new") {
      axiosInstance.get(`/time-off-entries/${timeOffEntryId}/`).then((response) => {
        reset(dehydrateValues(response.data))
      })
    }
		else if(id) {
			axiosInstance.get(`/time-off-entries/${id}/`).then((response) => {
				reset(dehydrateValues(response.data))
			})
		}
  }, [timeOffEntryId, reset, id])

	const fetchUsers = useCallback(async (filter: string | undefined) => {
		const { data } = await axiosInstance.get("/users/", {
			params: {
				query: filter,
			}
		})

		return data.results.map((x: User) => ({
		label: x.first_name,
		value: x.id,
		}))
	}, [])


	function onUpdateSuccess() {
		queryClient.invalidateQueries(["time-off-entries"])
		doReset()
	}

  const createMutation = useCreateTimeOffEntry({
		onSuccess: onUpdateSuccess,
  })

  /**
   * Update timeEntry on server
   */
  const updateMutation = useUpdateTimeOffEntry({
		onSuccess: onUpdateSuccess,
  })

  /**
   * Close and reset modal
   */
  const doReset = () => {
    setStartTime(null);
    setEndTime(null);
    setHours('');
	setOpen(false);
	setTimeOffEntryId(undefined);
	navigate(`/time-off-entries`);
	
	reset(defaultValues);
  }

  /**
   * Save the timeEntry. Will choose between create or update.
   */
  const doSave = useCallback(
	  (values: any) => {
		  if (id) {
			updateMutation.mutate({
				id: id,
				...hydrateValues(values),
			})
			return
		}
			if (timeOffEntryId === undefined) {
			handleTimeOff(values);
			createMutation.mutate({
				...hydrateValues(values),
			})	
			} else {
			updateMutation.mutate({
				id: timeOffEntryId,
				...hydrateValues(values),
			})
			}
		},
		[createMutation, updateMutation, timeOffEntryId]
  )

  const handleTimeOff = async (values: any) => {
	if(values.time_off_type.value === 'SICK') {
		const totalSickLeaves = await amountSickLeaves();
		const message = `${getUser().first_name} ${getUser().last_name} heeft zich ziek gemeld voor ${hydrateDateTime(values.date)}` +
		`, er zijn ${totalSickLeaves} ziektemedlingen dit jaar van ${getUser().first_name} ${getUser().last_name}`;
	 	sendSlackNotification(message);
	}

	if(values.time_off_type.value === 'VACATION') {
		const message = `${getUser().first_name} ${getUser().last_name} heeft vakantie aangevraagd voor ${hydrateDateTime(values.date)}`;
	 	sendSlackNotification(message);
	}
  };

  const countConsecutiveSickLeaves = (sickLeaves: string[]) => {
	let consecutiveCount = 0;
  
	for (let i = 0; i < sickLeaves.length - 1; i++) {
		const current: moment.Moment = moment(sickLeaves[i]);
		const next: moment.Moment = moment(sickLeaves[i + 1]);
	  // Check if the next date is one workday after the current date
	  if (next.diff(current, 'days') === -1 || next.isSame(current, 'day')) {
		consecutiveCount++;
	  }
	}
	return consecutiveCount;
  };

  const amountSickLeaves = async () => {
	const { data } = await axiosInstance.get(`/time-off-entries/?user=${getUser().id}&time_off_type=SICK&ordering=date`);
  
	const sickLeaves = data.results
	  .filter((item: any) => moment.utc(item.date).year() === moment().year())
	  .map((item: any) => item.date);
	  
	const consecutiveCount = countConsecutiveSickLeaves(sickLeaves);
  	const totalSickLeaves = sickLeaves.length - consecutiveCount;
  	return totalSickLeaves;
  };

  /**
   * Will dehydrate data from the API
   *
   * @param values
   */
  function dehydrateValues(values: any) {
		setHours(values.hours)
		setStartTime(values.start_time)
		setEndTime(values.end_time)
		return {
			...values,
			time_off_type: {
				label: TIME_OFF_TYPES.find((x) => x.value === values.time_off_type)?.label,
				value: values.time_off_type,
			},
			user: dehydrateForeignKey(values.user, values.first_name),
			start_time: dehydrateTime(values.start_time),
			end_time: dehydrateTime(values.end_time),
		}
  }

  /**
   * Wil hydrate data before sending it to the API.
   *
   * @param values
   */
	function hydrateValues(values: any) {
		const approved = hydrateSelect(values.time_off_type) === 'SICK' ? getUser().id
			: JSON.parse(values.approved_by) === true ? getUser().id : null;

		return {
			...values,
			date: hydrateDateTime(values.date),
			user: hydrateForeignKey(values.user),
			time_off_type: hydrateSelect(values.time_off_type),
			approved_by: approved,
			start_time: hydrateTime(values.start_time),
			end_time: hydrateTime(values.end_time),
		}
  }

  const handleStartTimeChange = (newValue: string | null) => {
    setStartTime(newValue);
    updateTotalHours(newValue, endTime);
  };

  const handleEndTimeChange = (newValue: string | null) => {
    setEndTime(newValue);
    updateTotalHours(startTime, newValue);
  };


  const updateTotalHours = (start: string | null, end: string | null) => {
  
	if (start !== null && end !== null) {
		const [startHours, startMinutes] = start.split(':').map(Number);
		const [endHours, endMinutes] = end.split(':').map(Number);
	
		const startTime = startHours * 60 + startMinutes;
		const endTime = endHours * 60 + endMinutes;
	
		const timeDifferenceInMinutes = endTime - startTime;
		const roundedHours = Math.round(timeDifferenceInMinutes / 60 * 10) / 10;	
		const formattedHours = !isNaN(roundedHours) ? Math.max(roundedHours, 0).toFixed(2) : '';
	
		setHours(formattedHours);
		setValue("hours", formattedHours);
	}
};

  return (
		<div>
				<Box sx={isMobile ? modalStyleMobile : modalStyle}>
				<form onSubmit={handleSubmit(doSave)}>
				<FormControl sx={{ my: 2, width: 1, display: getUser().is_superuser ? 'block' : 'none' }}>
					<AsyncSelectField
						isClearable
						label="Medewerker"
						control={control}
						loadOptions={fetchUsers}
						error={errors.user}
						{...register("user")}
					/>
				</FormControl>
				<FormControl sx={{ my: 2, width: 1}}>
					<StaticSelectField
						label="Type afwezigheid"
						control={control}
						options={TIME_OFF_TYPES}
						error={errors.time_off_type}
						{...register("time_off_type")}
					/>
				</FormControl>
				<FormControl sx={{ my: 2, width: 1 }}>
				<DateField
					label="Datum"
					control={control}
					{...register("date")}
					/>
				</FormControl>
				<Box sx={{ display: 'flex',justifyContent: 'space-between'}}>
					  <FormControl sx={{ my: 2, width: 0.45 }}>
					  <LocalizationProvider dateAdapter={AdapterMoment}>
							<TimeField
								label="Van"
								control={control}
								{...register("start_time")}
								  onChange={(newValue) => {
									handleStartTimeChange(newValue);
								}}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ my: 2, width: 0.45}}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<TimeField
								label="Tot"
								control={control}
								{...register("end_time")}
								onChange={(newValue) => {
									handleEndTimeChange(newValue);
								}}
							/>
						</LocalizationProvider>
					</FormControl>
				</Box>
				<Box sx={{width: 1, textAlign: 'left'}}>
					<FormControl sx={{ my: 2, width: 0.45 }}>
					<Controller
						control={control}
						name="hours"
						render={
						() => ( 
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								value={hours}
								type="number"
								label="Totaal uren"
								error={!!errors.hours}
								helperText={errors.hours ? errors.hours?.message?.toString() : null} 
								{...register("hours")}
								onChange={(e) => setHours(e.target.value)}
							/>)}		 
						/>
					</FormControl>
				  </Box>
				  <FormControl sx={{ my: 1, width: 1, display: getUser().is_superuser ? 'block' : 'none' }}>
					<CheckboxField
					control={control}
					{...register("approved_by")}
					label="Goed gekeurd" />
				  </FormControl>
				  
					<FormControl sx={{ my: 1, width:  1 }}>
					<Box sx={{ display: 'flex',justifyContent: 'space-between'}}>
						{isMobile ? 
						<Button sx={{ width: 0.3}} variant="outlined" onTouchEnd={doReset} >
							Cancel
						</Button>
						: 
						<Button sx={{ width: 0.3}} variant="outlined" onClick={doReset} >
							Cancel
						</Button>}

						<Button  sx={{width: 0.3, alignSelf : 'flex-end'}} variant="outlined" type="submit">
							Save
						</Button>
					</Box>
				</FormControl>
				</form>
			</Box>

	</div>
  );
}