import React, { useState, useMemo, useEffect, useCallback } from 'react';
import './App.css';
import { CssBaseline, useMediaQuery, Theme  } from "@mui/material";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  styled,
  useTheme,
} from "@mui/material/styles";
import { nlNL } from '@mui/material/locale';
import { LicenseInfo } from "@mui/x-license-pro";
import  Topbar  from './components/navigation/Topbar';
import  Sidebar  from './components/navigation/Sidebar';
import Routers from './Routes';
import getDesignTokens from './DesignTokens';
import { Routes, Route, useNavigate, useLocation  } from "react-router-dom";
import Box from '@mui/material/Box';
import moment from 'moment';
import LoginForm from './features/auth/LoginForm';
import ToastyContainer from "./components/ToastyContainer";
import TimeModalForm from './features/timeentries/TimeModalForm';
import useStore from './zustand/store'
import { sendTimedSlackNotification } from './xhr/slackNotification';
import 'moment/locale/nl';
moment.locale('nl');

LicenseInfo.setLicenseKey(
  "104e4ed16566c7868c9d01e26c89ac2bTz03Nzc0MyxFPTE3MzAzNzg0MDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
)

const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  ismobile?: string;
}>(({ theme, open, ismobile }) => ({
  height: '100%',
  flexGrow: 1,
  padding: ismobile === 'true' ? 0 : theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${ismobile === 'true' ? 0 :drawerWidth}px`,
  overflowY: ismobile === 'true' ? 'auto' : 'hidden',
  WebkitOverflowScrolling: ismobile === 'true' ? 'touch' : 'auto', // Enable touch scrolling when isMobile is true
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppbarDivider = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function isLoggedIn() {
  return localStorage.getItem("access_token") !== null;
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useState<"light" | "dark">("light")
  const [open, setOpen] = useState(false);
  const [timeEntryId, setTimeEntryId] = useState<string | undefined>()
  const [openModal, setOpenModal] = useState(false);  
  const setProjectStore = useStore((state) => state.setProject)
  const theme = useMemo(
    () => createTheme(getDesignTokens(mode), nlNL),
    [mode])
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (location.pathname === '/time-entries/add') {
      setProjectStore({project: undefined, project_client_name: undefined})
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [setOpenModal, location]);

  useEffect(() => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login")
    }
  }, [navigate])

  const onDataDelete = useCallback(() => {
		navigate('/time-entries')
	}, [])

  //let locale = i18next?.language == "nl" ? "nlNL" : "enUS"


  
  useEffect(() => {
    const intervalId = setInterval(sendTimedSlackNotification, 60000);
    return () => clearInterval(intervalId);
  }, [])
  

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        {/* Make Color mode to persists when you refresh the page. */}
        <Box sx={{ display: 'flex', flex: 1}}>
        <CssBaseline />
        <ToastyContainer /> 
        {!isLoggedIn() ?
        <>
          <Topbar open={open} setOpen={setOpen}/>
          <Box 
          display="flex"          
          sx={{
            flexDirection: 'column',
            mx: 'auto',
            justifyContent: 'center',
            //TODO: fix this in a better way through DesignTokens to implement mediaqueries
            width: 400,
            '@media(max-width: 600px)' : {
              width: 300
            }
          }}
          >
            <Routes>
                <Route path='/login'  key='/api/login' element={<LoginForm />} />                
            </Routes>
          </Box> 
          </> : 
          <>
         <Topbar open={open} setOpen={setOpen}/>
         {isMobile ? <> </> : <Sidebar open={open} setOpen={setOpen} navigate={navigate} Routers={Routers} />}
         
          <Main sx={{ flexDirection: 'column', display: 'flex', flex: 1, flexGrow: 1,}} open={open} ismobile={isMobile ? "true" : "false"}>
                <AppbarDivider />
           <Box sx={{flex: 1}}>
             <Routes>
               {Routers.map((route: any) => (
                 <Route path={route.path} key={route.path} element={<route.component /> }>
                 </Route>
               ))}
                  </Routes>
                  </Box>
             {openModal && !isMobile && (
              <TimeModalForm
              timeEntryId={timeEntryId}
              setTimeEntryId={setTimeEntryId}
              open={openModal}
              setOpen={setOpenModal}
              onDataDelete={onDataDelete}
                />
              )}
                {isMobile ? <Sidebar open={open} setOpen={setOpen} navigate={navigate} Routers={Routers} /> : <> </> }
         </Main>
          </>
        }
        </Box>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
