import React from 'react';
import TimeModalForm from './features/timeentries/TimeModalForm';
import TimeCalenderHeader from './features/timeentries/TimeCalenderHeader';
import TimeOffList from './features/timeoffentries/TimeOffList';
import Dashboard from './features/dashboard/Dashboard';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import EditIcon from '@mui/icons-material/Edit';
import TimeForm from './features/timeentries/TimeForm';

const Routers = [
  {
    icon: <DashboardRoundedIcon />,
    path: '/dashboard',
    sidebarName: 'Dashboard',
    component: Dashboard,
    showInSidebar: true,
    mobile: true,
  },
  {
    icon: <ScheduleRoundedIcon />,
    path: '/time-entries',
    sidebarName: 'Tijd',
    component: TimeCalenderHeader,
    showInSidebar: true,
    mobile: true,
  },
  {
    icon: <EditIcon />,
    path: '/time-entries/:id',
    sidebarName: 'Urenregel Aanpassen',
    component: TimeForm,
    showInSidebar: false,
    mobile: false,
  },
  {
    icon: <AddCircleRoundedIcon />,
    path: '/time-entries/add',
    sidebarName: 'Urenregel toevoegen',
    component: TimeModalForm,
    showInSidebar: true,
    mobile: false,
  },
  {
    icon: <DateRangeIcon />,
    path: '/time-off-entries',
    sidebarName: 'Afwezigheid',
    component: TimeOffList,
    showInSidebar: true,
    mobile: true,
  },
];

export default Routers;