import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Controller } from 'react-hook-form';
import { DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

interface IProps {
  name: string;
  error?: any;
  label?: string;
  control: any;
  isRequired?: boolean;
  disabled?: boolean;
  MUIprops?: any;
  onChange: (newValue: any) => void; // Add the onChange prop
}

const TimeField = React.forwardRef<HTMLInputElement, IProps>((props: IProps, ref) => {
  const { name, label, control, isRequired, disabled, MUIprops, onChange } = props;

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopTimePicker
              value={field.value ? moment(field.value, 'HH:mm') : null}
              inputFormat="HH:mm"
              disabled={disabled}
              {...MUIprops}
              onChange={(newValue: Moment | null) => {
                if (newValue) {
                  field.onChange(newValue.format('HH:mm'));
                  onChange(newValue.format('HH:mm')); // Pass the value to the parent's onChange
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={isRequired}
                  label={label}
                  error={!!error}
                  helperText={error ? error.message : null}
                  inputProps={{
                    ...params.inputProps,
                    'data-testid': field.name,
                  }}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </FormControl>
  );
});

TimeField.defaultProps = {
  isRequired: false,
  disabled: false,
};

export { TimeField };
