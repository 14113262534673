import * as React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom"
import { getUser } from '../../features/auth/services/user.service';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const drawerWidth = 240;

interface TopbarProps {
  open: boolean;
  setOpen?: (open: boolean) => void;
}

const MuiAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<TopbarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function Topbar({ open, setOpen }: TopbarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate()
  const isLoggedIn = localStorage.getItem("access_token") ? true : false;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogOut = () => {
    localStorage.clear();
    setAnchorEl(null);
    if (typeof window !== "undefined") {
      navigate("/login")
    }
  };

  return (
    <MuiAppBar position="fixed" open={open}>
      <Toolbar>
        {isLoggedIn ?
        <>
        {isMobile ? 
        <>
           <Box
            component="img"
            sx={{ height: 25, width: 25, ml: 1, mr: 1 }}
            src="/logo.png"
          />
          <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', flexGrow: 1 }}>
            Meierij IT
          </Typography>  
        </> :
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setOpen && setOpen(true)}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton> }
        <Box sx={{ display: 'flex', flexGrow: 1, alignItems:'center', flexDirection: 'row-reverse' }}>
          <IconButton
            size="large"
            onClick={handleMenu}
            color="inherit" >
            <AccountCircle />
          </IconButton>
          <Typography variant="h6"  sx={{ mr: 2 }}>
            {getUser().first_name}
          </Typography> 
        </Box>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					onClose={() => setAnchorEl(null)} >
						<MenuItem onClick={handleLogOut}>Afmelden</MenuItem>
				</Menu> 
        </>: 
        <>
          <Box
            component="img"
            sx={{ height: 25, width: 25, ml: 1, mr: 1 }}
            src="/logo.png"
          />
          <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', flexGrow: 1 }}>
            Meierij IT
          </Typography> 
          </>}
      </Toolbar>
    </MuiAppBar> 
  );
}
