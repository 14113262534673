import store from "../redux/store"

export function defaultSaveMessage(): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: "Default save message", // Replace with your desired message
      type: "success",
    },
  })
}

export function customSaveMessage(msg: string): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: msg,
      type: "success",
    },
  })
}

export function defaultDeleteMessage(): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: "Item is deleted", // Replace with your desired message
      type: "success",
    },
  })
}

export function defaultErrorMessage(description?: string): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      //message: "Default error message", // Replace with your desired message
      message: description ? description : "Er is een fout opgetreden",
      type: "error",
    },
  })
}
