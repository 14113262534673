export function hydrateSorting(params: any) {
    if (params.hasOwnProperty("sort") && Array.isArray(params.sort)) {
        let sorters: string[] = []

        params.sort.forEach((innerSorter: { id: string; desc: string }) => {
        let sortField = innerSorter.id

        if (innerSorter.desc) {
            sortField = `-${sortField}`
        }

        sorters.push(sortField)
        })

        params.sort = sorters.join(",")
    }

    return params
}
  
export function hydratePagination(params: any) {
    if (params.hasOwnProperty("pageIndex")) {
        params.page = params.pageIndex + 1

        delete params.pageIndex
    }

    if (params.hasOwnProperty("pageSize")) {
        params.limit = params.pageSize

        delete params.pageSize
    }

    return params
}


export function parseParams(params: any) {
    if (!params) {
      return
    }
  
    let _params = { ...params }
  
    _params = hydratePagination(_params)
    _params = hydrateSorting(_params)
  
    return {
      params: _params,
    }
  }