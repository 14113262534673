import React, { useState, useEffect, useCallback } from 'react';
import { 
    Box,
    Typography,
    useTheme,
	  useMediaQuery,
    IconButton,  
} from '@mui/material'
import SwipeableListItem from '../../components/SwipeableListItem';
import { useAppSelector } from "../../hooks/redux"
import LinearProgress from '@mui/material/LinearProgress';
import { IRestResponse } from "../../types/generic"
import { TimeEntry } from '../../types/all';
import axiosInstance from "../../xhr/axiosInstance"
import moment from 'moment';
import TimeForm from './TimeForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useDeleteTimeEntry } from './timetracking.hooks';
import { useQueryClient } from "@tanstack/react-query"
import { dehydrateForeignKey, dehydrateDateTime } from "../../helpers/normalizers"
import { getUser } from '../auth/services/user.service';
import TimeModalForm from './TimeModalForm';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import useStore from '../../zustand/store'

interface Props {
    date: moment.Moment
    updateTotalHours: (hours: number) => void;
    user: any;
}

export default function TimeCalender(props: Props) {
  const { date , updateTotalHours, user } = props;

	const dateStore = useStore((state) => state.date)
	const setDateStore = useStore((state) => state.setDate)

  const projectStore = useStore((state) => state.project)
  const setProjectStore = useStore((state) => state.setProject)

  const setUserStore = useStore((state) => state.setUser)
  const userStore = useStore((state) => state.user)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [timeEntryId, setTimeEntryId] = useState<string | undefined>()
  const [timeEntriesData, setTimeEntriesData] = useState<any[]>([]); 
  const [open, setOpen] = useState(false);
  const [totalHours, setTotalHours] = useState<number>(0);
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleEdit = (id: string) => {
    setTimeEntryId(id);
    setOpen(true);
    if(isMobile) {
    navigate(`/time-entries/${id}`);
    }
  };
  
	const onDataDelete = useCallback(() => {
    fetchData(); 
	}, [])

  /**
   * Will dehydrate data from the API
   *
   * @param values
   */
  function dehydrateValues(values: any) {
		return {
			...values,
			date: dehydrateDateTime(values.date),
			user: dehydrateForeignKey(values.user, values.first_name),
			project: dehydrateForeignKey(values.project, values.project_client_name),
		 }
  }

  const fetchData = async () => {
    try {
      let response;
      if(user.id !== undefined) {
        response = await axiosInstance.get(`/time-entries/?user=${user.id}&date=${date.format("YYYY-MM-DD")}`);
      } else {
        response = await axiosInstance.get(`/time-entries/?date=${date.format("YYYY-MM-DD")}`);
      }

      // const timeOffresponse = await axiosInstance.get(`/time-off-entries/?user=${getUser().id}&date=${props.date.format("YYYY-MM-DD")}`);

      const timeEntriesData = [
        ...response.data.results.map(dehydrateValues),
        // ...timeOffresponse.data.results.map(dehydrateValues),
      ];

      timeEntriesData.sort((a, b) => {
        const startTimeA = a.start_time ? moment(a.start_time, "HH:mm") : null;
        const startTimeB = b.start_time ? moment(b.start_time, "HH:mm") : null;
  
        return startTimeA && startTimeB ? startTimeA.diff(startTimeB) : 0;
      });

      setTimeEntriesData(timeEntriesData);

      const totalHours = response.data.results.reduce((total: number, entry: any) => {
      const hours = parseFloat(entry.hours);
      return total + hours;}, 0);
      props.updateTotalHours(totalHours);
      setTotalHours(roundToTwoDecimals(totalHours));
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  function roundToTwoDecimals(value: number) {
    const factor = 100;
    return Math.round(value * factor) / factor;
  }

	const handleCreateNewClick = (timeEntry: any) => {
    setUserStore({id: timeEntry.user, first_name: timeEntry.first_name})
    setDateStore(moment(timeEntry.date, 'DD-MM-YYYY'))
    setProjectStore({project: timeEntry.project, project_client_name: timeEntry.project_client_name})
		setTimeEntryId(undefined);
		setOpen(true);
  }
    
  useEffect(() => {
    fetchData();
  }, [date, user, open]);

  return (
    <Box>
      <div>
        {isMobile ? <></> :
          <Box sx={{bgcolor: 'lightgrey', mr:0.1, ml:0.1}}>
            <Typography>
              {totalHours} uur
            </Typography>
          </Box>
          }
        
        <Box sx={{mr:0.1, ml:0.1, border: 0.1, borderColor: 'lightgrey' }}>
        {timeEntriesData.map((timeEntry) => (
          
          <SwipeableListItem key={timeEntry.id} 
            data={timeEntry} 
            onDataDelete={onDataDelete}
          >
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', bgcolor: timeEntry.approved_by ? 'lightgreen': 'white'}}>
              <Box sx={{ width: '80%', display: 'flex'}}>
              <Box sx={{ marginRight: 1 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 'medium' }}>
                  {timeEntry.hours}
                </Typography>
              </Box>
              <Box sx={{   ml: 1}}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 'medium' }}>
                  {timeEntry.project_name}
                 {isMobile ? <></> : 
                 <IconButton sx={{m: 0, p: 0, selfAlign: 'end'}} 
                 onClick={() => handleCreateNewClick(timeEntry)} ><AddRoundedIcon sx={{color: "green"}} /></IconButton>
                } 
                </Typography>
                <Typography
                  onClick={() => handleEdit(timeEntry.id)}
                  sx={{
                    fontSize: 'medium',
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}>
                  {timeEntry.description}
                </Typography>
                <Typography sx={{  fontSize: 'medium' }}>
                  {timeEntry.time_off_type}
                </Typography>
                </Box>
        
              </Box>
              {timeEntry.start_time && timeEntry.end_time ? (
              <Box sx={{ width: '25%'}}>
                <Typography sx={{ fontSize: 'medium'}}>
                  {moment(timeEntry.start_time, "HH:mm").format("HH:mm")} - {moment(timeEntry.end_time, "HH:mm").format("HH:mm")}
                </Typography>
                </Box>
              ) : <></>}
            </Box>
          </SwipeableListItem>    
        ))}
        </Box>
        { isMobile ? <></> :
            <TimeModalForm 
            open={open} 
            timeEntryId={timeEntryId}
            setOpen={setOpen}
            setTimeEntryId={setTimeEntryId}
            onDataDelete={onDataDelete}
            />}
      </div>
    </Box>
  );
}