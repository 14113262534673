import axiosInstance from "../../xhr/axiosInstance";
import { IRestResponse, IRestParams} from "../../types/generic"
import { hydratePagination, hydrateSorting } from "../../helpers/queries"
import {
    defaultDeleteMessage,
    defaultErrorMessage,
    customSaveMessage,
} from "../../helpers/messages"
import  { TimeEntry }  from '../../types/all';
import { getUser } from "../auth/services/user.service";

// Read one
export async function fetchTimeEntry(uuid: string): Promise<TimeEntry> {
  const { data } = await axiosInstance.get(`/time-entries/${uuid}/`)
  return data
}

// List
export async function fetchTimeEntries(
  params: IRestParams
): Promise<IRestResponse<TimeEntry>> {
  let _params = params && hydratePagination(params)
  _params = params && hydrateSorting(params)
  if (!getUser().is_superuser) {
    const { data } = await axiosInstance.get(`/time-entries/?user=${getUser().id}`, {
      params: _params,
    })
    return data

  }
  else { 
    const { data } = await axiosInstance.get("/time-entries/", {
      params: _params,
    })
    return data
  }
}

// Create

export async function createTimeEntry(
  values: TimeEntry
): Promise<TimeEntry> {
  try {
    const { data } = await axiosInstance.post("/time-entries/", values)

    customSaveMessage("Tijdsslot is aangemaakt")

    return data
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}

// Update

export async function updateTimeEntry(
  values: TimeEntry
): Promise<TimeEntry> {
  try {
    const { data } = await axiosInstance.patch(
      `/time-entries/${values.id}/`,
      values
    )

    customSaveMessage("Tijdslot is aangepast")

    return data
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}

// Delete

export async function deleteTimeEntry(uuid: string): Promise<void> {
  try {
    await axiosInstance.delete(`/time-entries/${uuid}/`)

    defaultDeleteMessage()
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}