import axios from 'axios';
import moment from 'moment';
import axiosInstance from './axiosInstance';

export const sendSlackNotification = async (message: string) => {
  try {
    await axios.post('https://hooks.slack.com/services/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', {
      text: message,
    },  
    {
        headers: {
            'Content-Type': false,
        },
    });
  } catch (error) {
    console.error('Error sending Slack notification:', error);
  }
};

//Timed slack notification on 14:00 every day except weekends/timeoffdates
export const sendTimedSlackNotification = async () => {
  const currentTime = moment();
  const targetTime = moment().set({ hour: 14, minute: 0 });
  const isWeekend = currentTime.isoWeekday() === 6 || currentTime.isoWeekday() === 7;

  if (isWeekend) {
    return;
  }

  if (currentTime.isSame(targetTime, 'minute')) {
    const response = await axiosInstance.get(`/time-entries/?date=${moment().format('DD-MM-YYYY')}`);
    const userIDs = response.data.results.map((item: any) => item.user);
    const response2 = await axiosInstance.get(`/users`);
    const timeOffEntries = await axiosInstance.get(`/time-off-entries/?date=${moment().format('DD-MM-YYYY')}`);
    const timeOffUserIDs = timeOffEntries.data.results.map((item: any) => item.user);

    timeOffEntries.data.results.forEach((item: any) => {
      userIDs.push(item.user);
    });

    const filteredResults = response2.data.results.filter((item: any) => {
      return !userIDs.includes(item.id);
    });

    const filteredResultTimeOff = filteredResults.filter((item: any) => {
      return !timeOffUserIDs.includes(item.id);
    })

    const message = filteredResultTimeOff.map((item: any) => {
      return `${item.first_name} ${item.last_name}`;
    }).join(', ') + ' heeft de uren nog niet ingevuld voor vandaag.';

    sendSlackNotification(message);
  }
};
