
import React from 'react';
import { Modal } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import TimeOffForm from './TimeOffForm';

export interface IProps {
	setOpen : Function
	open : boolean
	timeOffEntryId: string | undefined
	setTimeOffEntryId: Function
}

export default function TimeModalForm(props: IProps) {
  const { timeOffEntryId, setTimeOffEntryId, open, setOpen } = props; 
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
		<Modal
		open={open}
		onClose={() => setOpen(false)}>
		<>
			<TimeOffForm
			timeOffEntryId={timeOffEntryId}
			setTimeOffEntryId={setTimeOffEntryId}
			setOpen={setOpen}/>
		</>
	  </Modal> 
	);
}