
import React from 'react';
import { Modal } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import TimeForm from './TimeForm';

export interface IProps {
	setOpen : Function
	open : boolean
	timeEntryId: string | undefined
	setTimeEntryId: Function
	onDataDelete: () => void
}

export default function TimeModalForm(props: IProps) {
  const { timeEntryId, setTimeEntryId, open, setOpen } = props; 
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const onDataDelete = props.onDataDelete

  return (
		<>
			{isMobile ? 
				<TimeForm
				timeEntryId={timeEntryId}
				setTimeEntryId={setTimeEntryId}
				setOpen={setOpen}
				onDataDelete={onDataDelete}/>
			:
			  <Modal sx={{
				overflowY: 'scroll',
				height:'100%',}}
				open={open}
			  >
				<>
					<TimeForm
					timeEntryId={timeEntryId}
					setTimeEntryId={setTimeEntryId}
					setOpen={setOpen}
					onDataDelete={onDataDelete}
					/>
				</>
			</Modal>	
			}
	</>
  );
}