import { create } from 'zustand'
import moment from 'moment'

type State = {
    date: moment.Moment;
    project: {
        project?: number;
        project_client_name?: string;
    };
    user: {
        id?: number;
        first_name?: string;
    },
}

type Action = {
    setDate: (date: moment.Moment) => void;
    setProject: (project: { project?: number; project_client_name?: string }) => void;
    setUser: (user: { id?: number; first_name?: string }) => void;
}
const useStore = create<State & Action>((set) => ({
    date: moment(),
    setDate: (date) => set(() => ({ date: date })),
    project: {
        project: 0,
        project_client_name: '',
    },
    user: {
        id: 0,
        first_name: '',
    },
    setProject: (project) => set(() => ({ project })),
    setUser: (user) => set(() => ({ user })),
}))

export default useStore