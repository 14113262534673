import { useAppSelector } from "../hooks/redux"
import store from "../redux/store"
import { Alert, Snackbar } from "@mui/material"
import React from "react"

export default function ToastyContainer() {
  const { isOpen, message, type, action } = useAppSelector(
    (state) => state.notifications
  )

  function handleClose() {
    store.dispatch({ type: "notifications/hideMessage" })
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert severity={type} sx={{ width: "100%" }} action={action}>
        {message}
      </Alert>
    </Snackbar>
  )
}
