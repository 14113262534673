import React, { useState, useEffect, useCallback } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import Card from '@mui/material/Card';
import axiosInstance from "../../xhr/axiosInstance"
import { getUser } from '../auth/services/user.service';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import {useForm} from "react-hook-form"
import Autocomplete from '@mui/material/Autocomplete';
import { indigo } from "@mui/material/colors"
import { 
  Box,    
  useTheme,
  useMediaQuery, 
} from '@mui/material';

export default function HoursBarChart() {
  const [totalHours, setTotalHours] = useState<number[]>([0,0,0,0,0,0,0]);
  const [date, setDate] = useState<moment.Moment>(moment());
  const [user, setUser] = useState(getUser());
  const [users, setUsers] = useState<any[]>([]);
  const baseColor = indigo
  const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const fetchUsers = async () => {
    const { data } = await axiosInstance.get('/users')
    setUsers(data.results);
  };

  const fetchData = async () => {
    try {
      const weekStartDate = date.clone().isoWeekday(1); 
      const weekEndDate = date.clone().isoWeekday(7);

      let response;
      if(!getUser().is_superuser){ 
        response = await axiosInstance.get(`/time-entries/?user=${getUser().id}&date__range=${weekStartDate.format("YYYY-MM-DD")},${weekEndDate.format("YYYY-MM-DD")}`);
      }
      else {
        response = await axiosInstance.get(`/time-entries/?user=${user.id}&date__range=${weekStartDate.format("YYYY-MM-DD")},${weekEndDate.format("YYYY-MM-DD")}`);
      }

      const totalHoursByDate: number[] = Array(7).fill(0);

      response.data.results.forEach((entry: any) => {
        const entryDate = moment(entry.date);
        const dayIndex = entryDate.isoWeekday() - 1; 
        const hours = parseFloat(entry.hours);
        totalHoursByDate[dayIndex] += hours;
      });
  
      setTotalHours(totalHoursByDate);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    fetchData();
  }, [user]);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setUser(newValue)
}

  return (
    <Card sx={{display: 'inline-flex', flexDirection:'column', flex: 1, justifyContent: 'center', alignSelf: 'start'}}>
      {getUser().is_superuser ? 
      <Autocomplete       
      disableClearable
      sx={{width: '45%', m: 2, alignSelf: 'end'}}
      options={users}
      onChange={handleChange}         
      getOptionLabel={(option) => option.first_name + ' ' + option.last_name}
      value={user}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Medewerker"
          InputProps={{
            ...params.InputProps,
          }}
          sx={{bgcolor: 'white', borderRadius: 2}}
        />
      )}                
    />
    :        
      <Box  sx={{width: '45%', m: 2, alignSelf: 'end',  fontWeight: 'bold'}}>
      {getUser().first_name + ' ' +getUser().last_name}
      </Box>
    }
      <BarChart
        xAxis={[
          {
            id: 'barCategories',
            data: [' ma ', ' di ', ' wo ',' do ',' vr ',' za ', ' zo '],
            scaleType: 'band',
          },
        ]}
        series={[
          {
            data: totalHours,
          },
        ]}
        width={isMobile ? 320 : 500}
        height={isMobile ? 200 : 300}
        sx={{
          '.MuiBarElement-root': {
            fill: baseColor[500],
            rx: 5,         
          },
        }}
      />
    </Card>
  )
}
