import React from 'react';
import { 
	Drawer, 
	Divider, 
	ListItem, 
	ListItemButton, 
	ListItemIcon, 
	ListItemText, 
	useMediaQuery, 
	useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface SidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  navigate: (path: string) => void;
  Routers: any[]; 
}


function Sidebar({ open, setOpen, navigate, Routers }:SidebarProps ) {
	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const renderSidebar = () => {
    if (isMobile) {
      return (
        //mobile-friendly layout
				<Box sx={{ bottom: 0, display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around'}}>
				{Routers.filter((route) => route.showInSidebar).map((item: any) => (
					<ListItem sx={{p: 0, flexGrow: 1, flexDirection: 'row', }} key={item.path} onClick={() => navigate(item.path)}>
						<ListItemButton sx={{justifyContent: 'center'}}>
								<ListItemIcon>{item.icon}</ListItemIcon>
						</ListItemButton>
					</ListItem>
				))}
			</Box>
      );
    } else {
      return (
        // desktop-friendly layout
        <div>
          <DrawerHeader>
            <Box
              component="img"
              sx={{ height: 25, width: 25, ml: 1, mr: 1 }}
              src="/logo.png"
            />
            <Typography variant="h6" noWrap component="div" sx={{ display: 'flex', flexGrow: 1 }}>
              Meierij IT
            </Typography>
            <IconButton color="inherit" onClick={() => setOpen(false)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {Routers.filter((route) => route.showInSidebar).filter((route) => route.mobile).map((item: any) => (
            <ListItem key={item.path} disablePadding onClick={() => navigate(item.path)}>
              <ListItemButton href={item.path}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.sidebarName} />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
      );
    }
  };

  return (
    <Drawer
      sx={{
        width: isMobile ? '100%' : drawerWidth,
				height:  isMobile ? '56px' : '100%',
        '& .MuiDrawer-paper': {
          width: isMobile ? '100%' : drawerWidth,
					height:  isMobile ? '56px' : '100%',
          boxSizing: 'border-box',
          flexDirection: isMobile ? 'row' : 'column',
          position: isMobile ? 'fixed' : '',
          bottom: isMobile ? 0 : '',
          top: isMobile ? 'auto' : '',
        },
      }}
      variant="persistent"
      anchor="left"
      open={isMobile ? true : open}
    >
      {renderSidebar()}
    </Drawer>
  );
}

export default Sidebar;
