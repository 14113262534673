import axiosInstance from "../../xhr/axiosInstance";
import { IRestResponse, IRestParams} from "../../types/generic"
import { hydratePagination, hydrateSorting } from "../../helpers/queries"
import {
  customSaveMessage,
  defaultDeleteMessage,
  defaultErrorMessage,
  defaultSaveMessage,
} from "../../helpers/messages"
import  { TimeOffEntry }  from '../../types/all';
import { getUser } from "../auth/services/user.service";


// Read one
export async function fetchTimeOffEntry(uuid: string): Promise<TimeOffEntry> {
  const { data } = await axiosInstance.get(`/time-off-entries/${uuid}/`)
  return data
}

// List
export async function fetchTimeOffEntries(
  params: IRestParams
): Promise<IRestResponse<TimeOffEntry>> {
  let _params = params && hydratePagination(params)
  _params = params && hydrateSorting(params)
  if (!getUser().is_superuser) {
    const { data } = await axiosInstance.get(`/time-off-entries/?user=${getUser().id}`, {
      params: _params,
    })
    return data

  }
  else { 
    const { data } = await axiosInstance.get("/time-off-entries/", {
      params: _params,
    })
    return data
  }
}

// Create
export async function createTimeOffEntry(
  values: TimeOffEntry
): Promise<TimeOffEntry> {
  try {
    const { data } = await axiosInstance.post("/time-off-entries/", values)

    customSaveMessage("Afwezigheid is aangemaakt");

    return data
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}

// Update

export async function updateTimeOffEntry(
  values: TimeOffEntry
): Promise<TimeOffEntry> {
  try {
    const { data } = await axiosInstance.patch(
      `/time-off-entries/${values.id}/`,
      values
    )

    customSaveMessage("Afwezigheid is aangepast");

    return data
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}

// Delete

export async function deleteTimeOffEntry(uuid: string): Promise<void> {
  try {
    await axiosInstance.delete(`/time-off-entries/${uuid}/`)

    defaultDeleteMessage()
  } catch (err) {
    defaultErrorMessage()

    throw err
  }
}