import { FixMeLater } from "@/types/generic"
import moment from "moment"

export function hydrateForeignKey(value: {
  key: number
  value: number
  label: string
}) {
  if (!value) {
    return undefined
  }

  return value.value
}

export function hydrateMultiForeignKey(
  values: [
    {
      key: number
      value: number
      label: string
    }
  ]
) {
  if (!values) {
    return []
  }

  return values
    .filter((x) => x.value !== undefined && x.value !== null)
    .map((x) => x.value)
}

export function hydrateSelect(value: {
  key: number
  value: string | number | undefined
  label: string
}): string | number | undefined {
  if (!value) {
    return ""
  }

  return value.value
}

export function dehydrateForeignKey(value: number, label: string) {
  if (!value || !label) {
    return undefined
  }

  return {
    value,
    label,
  }
}

export function dehydrateMultiForeignKey(
  values: Array<any>,
  valueAttr: string,
  labelAttr: string
) {
  if (!values || !labelAttr) {
    return undefined
  }

  return values.map((x) => ({
    value: x[valueAttr],
    label: x[labelAttr],
  }))
}

export function dehydrateDateTime(value: any) {
  return moment(value).format("DD-MM-YYYY")
}

export function hydrateDateTime(value: any) {
  return moment(value).format("YYYY-MM-DD")
}

export function dehydrateIso8601(value: any) {
  return moment(value)
}

export function hydrateMultiSelect(values: FixMeLater) {
  if (!values) {
    return []
  }

  return values.map((x: FixMeLater) => x.value)
}

export function dehydrateMultiSelect(values: [any], labels: [string]) {
  if (!values) {
    return []
  }

  return values.map((x: FixMeLater) => ({
    label: labels[x],
    value: x,
  }))
}

/**
 * Hydrate time in the required format (HH:mm:ss.uuuuuu).
 *
 * @param time
 */
export function hydrateTime(time: string | null) {
  if (time !== null) {
    const isValidTimeFormat = /^\d{2}:\d{2}(:\d{2})?$/.test(time);
  
    if (isValidTimeFormat) {
      const parsedTime = moment(time, 'HH:mm:ss').format('HH:mm:ss.SSSSSS');
      return parsedTime;
    } else {
    return null;
    }
  } else {
    return null;
  }
}

/**
 * Dehydrate time from the format (HH:mm:ss.uuuuuu) to (HH:mm:ss).
 *
 * @param hydratedTime
 */
export function dehydrateTime(hydratedTime: string | null) {
  if (hydratedTime !== null) {
    const isValidTimeFormat = /^\d{2}:\d{2}:\d{2}(\.\d{6})?$/.test(hydratedTime);

    if (isValidTimeFormat) {
      const parsedTime = moment(hydratedTime, 'HH:mm:ss.SSSSSS').format('HH:mm');
      return parsedTime;
    } else {
      return null;
    }
  } else {
    return null;
  }
}