export default function createFiltersWithNamedType(
    statusName = "",
    overriddenInitialState = null
  ) {
    let initialState: { [key: string]: any }
  
    if (overriddenInitialState) {
      initialState = overriddenInitialState
    } else {
      initialState = {
        search: "",
        rating: null,
        category: null,
        pagination: {
          pageIndex: 0,
          pageSize: 10,
        },
        sorter: [],
      }
    }
  
    interface IAction {
      type: string
      payload?: any
    }
  
    // eslint-disable-next-line @typescript-eslint/default-param-last
    return function filtersReducer(state = initialState, action: IAction) {
      switch (action.type) {
        // Search
  
        case `filters/reset_${statusName}`: {
          return initialState
        }
  
        case `filters/categoryFilterChanged_${statusName}`: {
          return {
            ...state,
            category: action.payload,
          }
        }
  
        case `filters/ratingFilterChanged_${statusName}`: {
          return {
            ...state,
            rating: action.payload,
          }
        }
  
        case `filters/searchFilterChanged_${statusName}`: {
          return {
            ...state,
            search: action.payload,
            pagination: initialState.pagination,
          }
        }
  
        // Sorter
  
        case `filters/sorterChanged_${statusName}`: {
          return {
            ...state,
            sorter: action.payload,
          }
        }
  
        // Pagination
        case `filters/paginationReset_${statusName}`: {
          return {
            ...state,
            pagination: initialState.pagination,
          }
        }
  
        case `filters/paginationChanged_${statusName}`: {
          return {
            ...state,
            pagination: {
              ...state.pagination,
              ...action.payload,
            },
          }
        }
  
        case `filters/paginationTotalChanged_${statusName}`: {
          return {
            ...state,
            pagination: {
              ...state.pagination,
              total: action.payload,
            },
          }
        }
        case `filters/paginationCurrentChanged_${statusName}`: {
          return {
            ...state,
            pagination: {
              ...state.pagination,
              current: action.payload,
            },
          }
        }
  
        default:
          return state
      }
    }
  }