import React, {useState, useEffect, useCallback } from 'react';
import { Box, 
	Button, 
	FormControl, 
	TextField, 
} from '@mui/material';
import { Project, User } from "../../types/all"
import axiosInstance from "../../xhr/axiosInstance"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import {
  useCreateTimeEntry,
  useUpdateTimeEntry,
} from "./timetracking.hooks"
import { useQueryClient } from "@tanstack/react-query"
import {
	dehydrateForeignKey,
	hydrateForeignKey,
	hydrateDateTime,
	hydrateTime,
	dehydrateTime,
} from "../../helpers/normalizers"
import { 
	DateField, 
	CheckboxField, 
	TextareaField,
	AsyncSelectField,
} from "@meierij-it/react-components-v2"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Controller } from "react-hook-form";
import { modalStyle, modalStyleMobile } from "../../styling"
import { getUser } from '../auth/services/user.service';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TimeField }  from '../../components/TimeField';
import useStore from '../../zustand/store'
import TimeDelete from './TimeDelete';

export interface IProps {
	setOpen : Function
	timeEntryId: string | undefined
	setTimeEntryId: Function
	onDataDelete: () => void
}

const schema = yup
  .object({
		date: yup.string().required("Dit veld is verplicht"),
		description: yup.string().required("Dit veld is verplicht"),
		project: yup.object().nullable().required("Dit veld is verplicht"),
		user: yup.object().nullable().required("Dit veld is verplicht"),
		internal_notes: yup.string(),
		hours: yup.string().nullable().required("Dit veld is verplicht")
		.test({
			name: 'min',
			message: 'Minimale waarde is 0',
			test: (value) => {
				if (!value) {
					return false;
				}
			const numericValue = parseFloat(value);
			return isNaN(numericValue) || numericValue >= 0;
			},
		}),
 		is_billable: yup.string(),
		is_invoiced: yup.string(),
		start_time: yup.string().nullable(),
		end_time: yup.string().nullable(),
})
.required();

export default function TimeForm(props: IProps) {
	const { timeEntryId, setTimeEntryId, setOpen } = props; 
	const dateStore = useStore((state) => state.date)
	const projectStore = useStore((state) => state.project)
	const userStore = useStore((state) => state.user)
	const navigate = useNavigate();
	const [openDelete, setDeleteOpen] = useState(false);
	const [data, setData] = useState<any>(undefined);
	const defaultValues = {
		is_billable: true, 
		is_invoiced: false, 
		date: dateStore ? dateStore : new Date(), 
		user: userStore.id && userStore.first_name ? dehydrateForeignKey(userStore?.id, userStore?.first_name) : dehydrateForeignKey(getUser().id, getUser().first_name),
		project: projectStore.project && projectStore.project_client_name ? dehydrateForeignKey(projectStore?.project, projectStore?.project_client_name) : undefined
	}
	
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const queryClient = useQueryClient()
	const {
	control,
	handleSubmit,
	register,
	reset,
	setValue,
	formState: { errors },
  } = useForm<any>({
	defaultValues,
	shouldUnregister: true,
	resolver: yupResolver(schema),
  })

	const [startTime, setStartTime] = useState<string | null>(null);;
	const [endTime, setEndTime] = useState<string | null>(null);;
	const [Hours, setHours] = useState('');
	const { id } = useParams();

  useEffect(() => {
    if (timeEntryId && timeEntryId !== "new") {
      axiosInstance.get(`/time-entries/${timeEntryId}/`).then((response) => {
        reset(dehydrateValues(response.data))
		setData(dehydrateValues(response.data));
      })
    }
		else if(id) {
			axiosInstance.get(`/time-entries/${id}/`).then((response) => {
				reset(dehydrateValues(response.data))
				setData(dehydrateValues(response.data));
			})
		}

  }, [timeEntryId, reset, id])
  
	const fetchUsers = useCallback(async (filter: string | undefined) => {
    const { data } = await axiosInstance.get("/users/", {
				params: {
					query: filter,
				}
		})

    return data.results.map((x: User) => ({
      label: x.first_name,
      value: x.id,
    }))
  }, [])

  const fetchProjects = useCallback(async (filter: string | undefined) => {
	  const { data } = await axiosInstance.get("/projects/", {
		params: {
			query: filter,
		}
	})
    return data.results.map((x: Project) => ({
      label: `${x.client_name} - ${x.name}`,
      value: x.id,
    }))
  }, [])

	function onUpdateSuccess() {
		queryClient.invalidateQueries(["time-entries"])
		doReset()
	}

  const createMutation = useCreateTimeEntry({
		onSuccess: onUpdateSuccess,
  })

  /**
   * Update timeEntry on server
   */
  const updateMutation = useUpdateTimeEntry({
		onSuccess: onUpdateSuccess,
  })

  /**
   * Close and reset modal
   */
  const doReset = () => {
    setStartTime(null);
    setEndTime(null);
    setHours('');
	if(!isMobile) {
		setOpen(false);
		setTimeEntryId(undefined);
	} else {
		navigate(`/time-entries/`);
	}
	reset(defaultValues);
  }

  /**
   * Save the timeEntry. Will choose between create or update.
   */
  const doSave = useCallback(
	  (values: any) => {

			if(id){
				updateMutation.mutate({
					id: id,
					...hydrateValues(values),
				})
				return
			}
			if (timeEntryId === undefined) {
			createMutation.mutate({
				...hydrateValues(values),
			})	
			} else {
			updateMutation.mutate({
				id: timeEntryId,
				...hydrateValues(values),
			})
			}
		},
		[createMutation, updateMutation, timeEntryId]
  )

  /**
   * Will dehydrate data from the API
   *
   * @param values
   */
	function dehydrateValues(values: any) {
		setEndTime(values.end_time);
		setStartTime(values.start_time);
		setHours(values.hours);
		return {
			...values,
			user: dehydrateForeignKey(values.user, values.first_name),
			project: dehydrateForeignKey(values.project, values.project_client_name),
			start_time: dehydrateTime(values.start_time),
			end_time: dehydrateTime(values.end_time), 
		 }
  }

  /**
   * Wil hydrate data before sending it to the API.
   *
   * @param values
   */
	function hydrateValues(values: any) {
		return {
			...values,
			date: hydrateDateTime(values.date),
			user: hydrateForeignKey(values.user),
			project: hydrateForeignKey(values.project),
			start_time: hydrateTime(values.start_time),
			end_time: hydrateTime(values.end_time),
		}
  }

	const handleStartTimeChange = (newValue: string | null) => {
		setStartTime(newValue);
		updateTotalHours(newValue, endTime);
	};

  const handleEndTimeChange = (newValue: string | null) => {
    setEndTime(newValue);
	updateTotalHours(startTime, newValue);	
  };

  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const onDataDelete = useCallback(() => {
	doReset();
	props.onDataDelete();
  }, [props])

  const updateTotalHours = (start: string | null, end: string | null) => {

		if (start !== null && end !== null) {
			const [startHours, startMinutes] = start.split(':').map(Number);
			const [endHours, endMinutes] = end.split(':').map(Number);
		
			const startTime = startHours * 60 + startMinutes;
			const endTime = endHours * 60 + endMinutes;
		
			const timeDifferenceInMinutes = endTime - startTime;
			const roundedHours = Math.round(timeDifferenceInMinutes / 60 * 10) / 10;	
			const formattedHours = !isNaN(roundedHours) ? Math.max(roundedHours, 0).toFixed(2) : '';
		
			setHours(formattedHours);
			setValue("hours", formattedHours);
		}
	};
  
  return (
		<div>
				<Box sx={isMobile ? modalStyleMobile : modalStyle}>
				<form onSubmit={handleSubmit(doSave)}>
				<FormControl sx={{ my: 1, width: 1, display: getUser().is_superuser ? 'block' : 'none' }}>
					<AsyncSelectField
						isClearable
						label="Medewerker"
						control={control}
						loadOptions={fetchUsers}
						error={errors.user}
						{...register("user")}
					/>
				</FormControl>
				<FormControl sx={{ my: 1, width: 1 }}>
					<AsyncSelectField
						isClearable
						label="Client | Project"
						control={control}
						loadOptions={fetchProjects}
						error={errors.project}
						{...register("project")}
					/>
				</FormControl>
				<FormControl sx={{ my: 1, width: 1 }}>
				<DateField
					label="Datum"
					control={control}
					{...register("date")}
					/>
				</FormControl>
				<FormControl sx={{ my: 1, width: 1 }}>
					<TextareaField
					label="Beschrijving"
					control={control}
					{...register("description")}
					/>
					</FormControl>
				<FormControl sx={{ my: 1, width: 1 }}>
					<TextareaField
					label="Interne notities"
					control={control}
					{...register("internal_notes")}
					/>
				</FormControl>
				<Box sx={{ display: 'flex',justifyContent: 'space-between'}}>
					<FormControl sx={{ my: 1, width: 0.45}}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<TimeField
								label="Van"
								control={control}
								{...register("start_time")}
								  onChange={(newValue) => {
									handleStartTimeChange(newValue);
								}}
							/>
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ my: 1, width: 0.45}}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<TimeField
								label="Tot"
								control={control}
								{...register("end_time")}
								onChange={(newValue) => {
									handleEndTimeChange(newValue);
								  }}
							/>
						</LocalizationProvider>
					</FormControl>
				</Box>
				<Box sx={{width: 1, textAlign: 'left'}}>
					<FormControl sx={{ my: 1, width: 0.45 }}>
					<Controller
						control={control}
						name="hours"
						render={
						() => ( 
							<TextField
								InputLabelProps={{
									shrink: true,
								}}
								value={Hours}
								type="number"
								label="Totaal uren"
								error={!!errors.hours}
								helperText={errors.hours ? errors.hours?.message?.toString() : null} 
								{...register("hours")}
								onChange={(e) => setHours(e.target.value)}
							/>)}		 
						/>
					</FormControl>
				</Box>
				<FormControl sx={{ my: 1, width: 1}}>
					<CheckboxField
					control={control}
					{...register("is_billable")}
					label="Is factureerbaar" />
				</FormControl>
				<FormControl sx={{ my: 1, width: 1, display: getUser().is_superuser ? 'block' : 'none' }}>
					<CheckboxField
					control={control}
					{...register("is_invoiced")}
					label="Is gefactureerd" />
				</FormControl>
				<FormControl sx={{ my: 1, width:  1 }}>
					<Box sx={{ display: 'flex',justifyContent: 'space-between'}}>
						<Button sx={{ width: 0.3}} variant="outlined" onClick={doReset} >
							Cancel
						</Button>
						{!isMobile && (timeEntryId || id) ?  
						<Button sx={{ width: 0.3}} variant="outlined" color="error"  onClick={handleDelete} disabled={(data as any)?.is_invoiced}>
							Delete
						</Button> : <></> }	
						<Button  sx={{width: 0.3, alignSelf : 'flex-end'}}  variant="outlined" type="submit">
							Save
						</Button>
					</Box>
				</FormControl>
				</form>
			</Box>
			<TimeDelete
				setDeleteOpen={setDeleteOpen}
				openDelete={openDelete}
				timeEntry={data}
				onDataDelete={onDataDelete}
			/>

	</div>
  );
}
