export const modalStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	// border: '2px solid #000',
	// borderRadius: 10,
	boxShadow: 24,
	p: 4,
};

export const modalStyleMobile = {
	width: '100%',
	height: '100%',
	bgcolor: 'background.paper',
	p: 4,
};
  
  