 import { green } from "@mui/material/colors"
 import { red } from "@mui/material/colors"
 import { deepOrange } from "@mui/material/colors"
 import { indigo } from "@mui/material/colors"
 import { purple, blue, grey, orange, teal } from "@mui/material/colors"
import { blueGrey } from "@mui/material/colors"
import { amber } from "@mui/material/colors"
import { PaletteMode } from "@mui/material"
import { ThemeOptions } from "@mui/material"

const baseColor = blueGrey

const white = "#ffffff"

const backgroundLight = {
  sidebar: baseColor[900],
  default: white,
  paper: white,
  wrapper: "#f7fbfb",
}

const backgroundDark = {
  sidebar: baseColor[900],
  default: baseColor[900],
  paper: baseColor[800],
}

const getDesignTokens = (mode: PaletteMode) =>
  ({
    
    typography: {
      fontSize: 16,
      allVariants: {
        // fontFamily: "Inter",
        // textTransform: 'none',
        // fontSize: 16,
      },
    },
    components: {
      MuiCard: {
        root: {
          borderRadius: 97,
        },
        defaultProps: {
          sx: {
            backgroundColor: mode === "light" ? white : baseColor[600],
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1);",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "small",
          disableElevation: true,
          sx: {
            fontWeight: "normal",
            backgroundColor: mode === "light" ? baseColor[900] : baseColor[700],
            // color: mode === "light" ? white : baseColor[50],
            textTransform: "none",
            // fontWeight: 'bold',
            paddingX: 2,
          },
        },
      },
      MuiButtonGroup: {
        defaultProps: {
          size: "small",
        },
      },
      MuiCheckbox: {
        defaultProps: {
          size: "small",
        },
      },
      MuiChip: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFab: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControl: {
        defaultProps: {
          // margin: 'dense',
          size: "small",
          //shrink: true,
        },
      },
      MuiFormHelperText: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: "small",
        },
      },
      MuiInputBase: {
        defaultProps: {
          margin: "dense",
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            display: 'flex',
            position: 'fixed',  
          },
          paper: {
            position: 'fixed',
          }
        },
      },
      MuiInputLabel: {
        defaultProps: {
        },
      },
      MuiRadio: {
        defaultProps: {
          size: "small",
        },
      },
      MuiSwitch: {
        defaultProps: {
          size: "small",
        },
      },
      MuiTextField: {
        defaultProps: {
          margin: "dense",
          size: "small",
        },
      },
      MuiList: {
        defaultProps: {
          dense: true,
        },
      },
      MuiMenuItem: {
        defaultProps: {
          dense: true,
        },
      },
      MuiTable: {
        defaultProps: {
          size: "small",
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {},
          },
        },
        defaultProps: {
          sx: {
            textTransform: "none",
            fontSize: "0.9rem",
            letterSpacing: "inherit",
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: "none",
        },
      },
      MuiAppBar: {
        defaultProps: {
          // backgroundColor: mode === "light" ? white : baseColor[900],
        },
      },
      MuiBackDrop: {
        root: {
          opacity: 0.5,
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            display: 'block',            
            borderRadius: 10,
            borderColor: baseColor[100],
            border: '2px solid primary.light', 
          },
          paper: {
            boxShadow: '-2px 2px 5px 3px rgba(0, 0, 0, 0.1)', 
          },
        },
      },
    
    },
    palette: {
      text: {
        primary: "#333",
      },
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: baseColor[500],
              light: baseColor[50],
              dark: baseColor[900],
            },
            secondary: amber,
            // divider: grey[800],
            // text: {
            //   primary: grey[600],
            //   secondary: grey[500],
            // },
            background: backgroundLight,
          }
        : {
            // palette values for dark mode
            primary: {
              main: baseColor[500],
              light: baseColor[50],
              dark: baseColor[900],
            },
            secondary: amber,
            background: backgroundDark,
            // divider: grey[800],
            // background: {
            //   default: grey[600],
            //   paper: grey[500],
            // },
            // text: {
            //   primary: "#fff",
            //   secondary: grey[500],
            // },
          }),
    },
  } as ThemeOptions)

export default getDesignTokens;