import React, { useState, useRef } from 'react';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import TimeDelete from '../features/timeentries/TimeDelete';

interface SwipeableListItemProps<T> {
  data: T;
  children?: React.ReactNode;
  onTouchEnd?: (item: T) => void;
  onDataDelete: () => void;
}

function SwipeableListItem<T>({ data, children, onTouchEnd, onDataDelete }: SwipeableListItemProps<T>) {
  const [isSwipeOpen, setSwipeOpen] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0 });
  const [openDelete, setDeleteOpen] = useState(false);
  const listItemRef = useRef<HTMLLIElement | null>(null);

  const handleTouchMove = (e: React.TouchEvent) => {

    const touch = e.touches[0];
    const deltaX = touchStartX - touch.clientX;

    if (deltaX > 50) {
      if (!listItemRef.current) {
        return;
      }
      const listItemRect = listItemRef.current.getBoundingClientRect();
      setAnchorPosition({ top: listItemRect.top });
  
      setSwipeOpen(true);
      setShowDeleteButton(true);
    }
  };

  let touchStartX = 0;

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    touchStartX = touch.clientX;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    setSwipeOpen(false);
  };

  const handleItemEvent = (e: React.MouseEvent) => {
    if (onTouchEnd && !isSwipeOpen && data) {
      onTouchEnd(data);
    }
  };

  const handleDelete = () => {
    setDeleteOpen(true);
  };

  return (
    <Box sx={{ borderTop: 1, borderColor: 'primary.light' }}>
      <ListItem
        ref={listItemRef}
        sx={{  
          height: '100%',
          marginLeft: isSwipeOpen ? `-20vw` : 0,
          transition: 'margin-left 0.3s ease-out',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onClick={handleItemEvent}
      >
        {children}
        </ListItem>
      <SwipeableDrawer
        sx={{
          zIndex: 1100, width: '20vw',
        }}
        anchor="right"
        open={isSwipeOpen}
        onOpen={handleTouchMove}
        onTouchMove={handleTouchMove}
        onClose={handleTouchEnd}
        PaperProps={{
          style: {
            boxShadow: 'none',
            height: listItemRef.current ? listItemRef.current.clientHeight : 'auto',
            width: '20vw',
            alignItems: 'center',
            top: anchorPosition.top,
            position: 'fixed',
          },
        }}
        slotProps={{
          backdrop: {
            invisible: true,
          },
        }}
      >
        {showDeleteButton && (
          <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', bgcolor: (data as any)?.is_invoiced ? 'grey' : 'red' }}>
            <Button
              sx={{
                borderRadius: 0,
                display: 'flex',
                flexGrow: 1,
              }}
              disabled={(data as any)?.is_invoiced}
              onClick={handleDelete}
            >
              <DeleteIcon sx={{ color: 'white' }} />
            </Button>
          </Box>
        )}
      </SwipeableDrawer>
      <TimeDelete
        setDeleteOpen={setDeleteOpen}
        openDelete={openDelete}
        timeEntry={data}
        onDataDelete={onDataDelete}
      />
    </Box>
  );
}

export default SwipeableListItem;


