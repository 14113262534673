import {
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Box,
	useTheme,
	useMediaQuery
} from "@mui/material"
import React, { useCallback } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useDeleteTimeEntry } from "./timetracking.hooks"


export interface IProps {
  	setDeleteOpen: (open: boolean) => void
	openDelete: boolean
	timeEntry: any
	onDataDelete: () => void
}

export default function TimeDelete(props: IProps) {
	const { setDeleteOpen, openDelete, timeEntry, onDataDelete } = props
	const [hasFetchedData, setHasFetchedData] = React.useState(false); // New state variable

	const queryClient = useQueryClient()
	const theme = useTheme();
  	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const onClose = () => {
		setHasFetchedData(false);
		onDataDelete()
		setDeleteOpen(false)
	}

	/**
	 * Runs after successful deletion
	 */
	function onDeleteSuccess() {
		queryClient.invalidateQueries(["time-entries"])
		onClose()
	}

	const deleteMutation = useDeleteTimeEntry({
		onSuccess: onDeleteSuccess,
	})
		
	/**
	 * Delete timeEntry on server
	 */
	const onDelete = useCallback(() => {
	if (timeEntry) {
		deleteMutation.mutate(timeEntry.id)
	}
	}, [timeEntry])


	const doDelete = useCallback(() => {
		onDelete()
	}, [onDelete])

	return (
			<Dialog open={openDelete} onClose={onClose}>
			<DialogTitle>
					<Grid container justifyContent="space-between">
							<Grid item>
								<Typography variant="h6">
								Dit wordt verwijderd: ID: {timeEntry?.id}
								</Typography>
							</Grid>
							<Grid item>
							</Grid>
					</Grid>
					</DialogTitle>
					<DialogContent dividers>
					{timeEntry ? 
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box><span style={{ fontWeight: 'bold' }}>Gebruiker:</span> {timeEntry.first_name}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Datum:</span> {timeEntry.date}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Project:</span> {timeEntry.project_client_name}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Beschrijving:</span> {timeEntry.description}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Interne notities:</span> {timeEntry.internal_notes}</Box>
							<Box><span style={{ fontWeight: 'bold' }}>Hoeveelheid uren:</span> {timeEntry.hours}</Box>

						</Box>
						: <></>
					}
					</DialogContent>
					<DialogActions>
						<Button variant="outlined"  onClick={onClose} sx={{bgcolor: 'primary'}}>
							Cancel
						</Button>
						<Button variant="outlined" color="error" sx={{bgcolor: 'primary'}} onClick={doDelete} >
							Delete
						</Button>
					</DialogActions>
			</Dialog>
	)
}
