import { AlertColor } from "@mui/material"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

export interface NotificationState {
  isOpen: boolean
  message: string
  type: AlertColor
  action?: any
}

const initialState: NotificationState = {
  isOpen: false,
  message: "",
  type: "success",
}

export interface NotificationPayload {
  message: string
  type: AlertColor
  action?: any
}

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showMessage: (state, action: PayloadAction<NotificationPayload>) => {
      state.isOpen = true
      state.message = action.payload.message
      state.type = action.payload.type
      state.action = action.payload.action
    },
    hideMessage: (state) => {
      state.isOpen = false
      // state.message = ''
      // state.type = ''
    },
  },
})

// Action creators are generated for each case reducer function
export const { showMessage, hideMessage } = notificationSlice.actions

export default notificationSlice.reducer
