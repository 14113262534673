import React from 'react'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { TimeOffEntry } from '../../types/all';
import { dehydrateDateTime } from "../../helpers/normalizers"
import { Box, IconButton } from '@mui/material';
import { DataGridPro, GridPaginationModel } from '@mui/x-data-grid-pro';
import { useFetchTimeOffEntries } from './timeoffentries.hooks';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../hooks/redux';
import { useState } from 'react';
import { IRestResponse } from "../../types/generic"
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
// import { useFetchCurrentUser } from '../users/users.hooks';
import TimeOffModalForm from './TimeOffModalForm';
import { getUser } from '../auth/services/user.service';
import { GridSortItem } from '@mui/x-data-grid-pro';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import TimeOffDelete from './TimeOffDelete';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import TimeOffTotal from './TimeOffTotal';

export default function TimeOffList() {
  const dispatch = useDispatch()
  const { search, pagination, sorter } = useAppSelector(
    (state) => state.timeoffentries
  )  
  const [open, setOpen] = useState(false);
  const [timeOffEntryId, setTimeOffEntryId] = useState<string | undefined>()
  const [openDelete, setDeleteOpen] = useState(false);

  const timeOffEntriesQuery = useFetchTimeOffEntries(
    {
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize,
      sort: sorter,
      query: search,
    },
    {
      onSuccess: onFetchSuccess,
      keepPreviousData: true,
    }
  )

  const handleCreateNewClick = () => {
    setTimeOffEntryId(undefined);
    setOpen(true);
  }
  function onDataDelete() {
    setDeleteOpen(false);
    window.location.reload();
  }

  function ActionsCell(params: GridRenderCellParams) {  
    const handleEditClick = (id: string) => () => {
      setTimeOffEntryId(id);
      setOpen(true);
    }
    const handleDeleteClick = (id: string) => () => {
      setTimeOffEntryId(id);
      setDeleteOpen(true);
    }

    return (
      <>
        <IconButton sx={{ cursor: "pointer" }} disabled={params.row.approved_by != null && !getUser().is_superuser} onClick={handleEditClick(params.row.id)}>
          <EditRoundedIcon />
        </IconButton>
        <IconButton sx={{ cursor: "pointer" }} disabled={params.row.approved_by != null && !getUser().is_superuser} onClick={handleDeleteClick(params.row.id)}>
            <DeleteRoundedIcon />
        </IconButton>        
      </>
    )
  }
  /**
   *
   * @param response
   */
  function onFetchSuccess(response: IRestResponse<TimeOffEntry>) {
    dispatch({
      type: "filters/paginationChanged_TIMEOFFENTRIES",
      payload: {
        pageCount: Math.ceil(response.count / pagination.pageSize),
        total: response.count,
      },
    })
  }
	const TIME_OFF_TYPES = [
		{ value: 'VACATION', label: 'Vakantie' },
		{ value: 'SICK', label: 'Ziek' },
		{ value: 'UNPAID', label: 'Onbetaald' },
		{ value: 'OTHER', label: 'Anders' },
	];

  interface DateColumn {
      field: string;
      value?: any; 
  }

  const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'ID', 
      flex: 1,
      sortable: false,
    },
    { 
      field: 'date', 
      headerName: 'Datum', 
      renderCell: (info: DateColumn) => dehydrateDateTime(info.value),
      flex: 3,
      sortable: false,
    },
    { 
      field: 'time_off_type', 
      headerName: 'Type',
      flex: 2.75 ,
      sortable: false,
      renderCell: (params) => {
        const timeOffTypeLabel = TIME_OFF_TYPES.find(
          (type) => type.value === params.value
        )?.label || params.value;

        return <>{timeOffTypeLabel}</>;
      },
    },
    { 
      field: 'hours', 
      headerName: 'Uren', 
      type: 'number', 
      flex: 1,
      sortable: false,
    },
    { 
      field: 'approved_by', 
      headerName: 'Gekeurd' , 
      renderCell: (params) => (
        <>
          {params.value ? <CheckRoundedIcon color="success" /> : <> </>}
        </>
      ),
      flex: 2.5,
      sortable: false, 
    },
    {
      headerName: "Acties",
      field: "actions",
      flex: 2.5,
      renderCell: ActionsCell,
    }
  ]
  if (getUser().is_superuser) {
    columns.splice(1, 0, {
      field: 'first_name', 
      headerName: 'Gebruiker', 
      flex: 2
    });
  }

  return (
    <Box sx={{ height: '50%', width: '100%'}}>
      <h2></h2>
    <TimeOffTotal />
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2, alignItems:'center' }}>
      <Button onClick={handleCreateNewClick} variant="contained" endIcon={<AddIcon />}>Afwezigheid regel</Button>
    </Box>
    <TimeOffDelete setDeleteOpen={setDeleteOpen} openDelete={openDelete} timeOffEntryId={timeOffEntryId} setTimeOffEntryId={setTimeOffEntryId} onDataDelete={onDataDelete} />
    <TimeOffModalForm  
      timeOffEntryId={timeOffEntryId}
      setTimeOffEntryId={setTimeOffEntryId}
      setOpen={setOpen}
      open={open}
      />
    <DataGridPro
    columns={columns}
    disableColumnMenu
    disableColumnSelector
    getRowHeight={() => 'auto'}
    disableRowSelectionOnClick
    autoHeight
    rows={timeOffEntriesQuery.isSuccess ? timeOffEntriesQuery.data.results : []}
    rowCount={timeOffEntriesQuery.isSuccess ? timeOffEntriesQuery.data.count : 0}
    loading={timeOffEntriesQuery.isLoading}
    pagination={true}
    paginationMode="server"
    pageSizeOptions={[10, 25, 50, 100]}
    initialState={{
      pagination: {
        paginationModel: {
          pageSize: pagination.pageSize,
          page: pagination.pageIndex,
        },
      },
      sorting: {
        sortModel: [{ field: 'date', sort: 'desc' }]
    },
    }}
    onPaginationModelChange={(
      newPaginationModel: GridPaginationModel
    ) => {
      dispatch({
        type: "filters/paginationChanged_TIMEOFFENTRIES",
        payload: {
          pageIndex: newPaginationModel.page,
          pageSize: newPaginationModel.pageSize,
        },
      })
    }}
    onSortModelChange={(newSorter) =>
      dispatch({
        type: "filters/sorterChanged_TIMEOFFENTRIES",
        payload: newSorter.map((sorter: any) => ({
          id: sorter.field,
          desc: sorter.sort === "desc",
        })),
      })
    }
    disableColumnFilter
    />

  </Box>
  )
}
